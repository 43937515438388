<template>
	<button
		type="reset"
		class="button--outline"
		@click="handleClick()"
	>
		<slot>Reset</slot>
	</button>
</template>

<script>
export default {
	name: "buttonResetQuestion",
	methods: {
		// Delete local draft and reload page
		resetQuestion: function(event) {
			this.$store
				.dispatch("RESET_QUESTION_DRAFT")
				.then(() => this.$router.go());
		},

		handleClick: function(event) {
			this.resetQuestion();
			this.$emit("click");
		}
	}
};
</script>
